<template>
  <div class="login-container">
    <form class="card card-login" @submit.prevent="login">
      <img class="img-login mb-1" src="~@/assets/images/logo.png" />
      <!-- <h2 class="card-title text-center my-0">Iniciar sesión</h2> -->
      <pd-input
        id="cuil"
        class="mt-1"
        label="CUIL"
        placeholder="Ingrese su cuil"
        type="text"
        prepend="key"
        v-model="usuario.cuil"
      ></pd-input>
      <pd-input
        id="password"
        class="mt-1"
        label="Contraseña"
        placeholder="Ingrese su contraseña"
        type="password"
        prepend="lock"
        v-model="usuario.password"
      ></pd-input>
      <button
        id="btn-login"
        class="btn btn-primary btn-align-right mt-1"
        type="submit"
      >
        Iniciar sesión
      </button>
    </form>
  </div>
</template>
<script>
// import { required } from "vuelidate/lib/validators";
import Swal from "sweetalert2";
import { mapActions } from "vuex";
export default {
  name: "IniciarSesion",
  login() {
    this.$router.push("/");
  },
  data() {
    return {
      usuario: {},
    };
  },
  async mounted() {
    let title = document.querySelector("title");
    title.innerHTML = `Iniciar sesión - Aguas del colorado`;
    // await this.activarNotificaciones();
  },
  computed: {
    isIdle() {
      return this.$store.state.idleVue.isIdle;
    },
  },
  methods: {
    ...mapActions({
      loginAction: "login",
    }),
    login() {
      this.enviado = true;
      this.loginAction(this.usuario)
        .then((r) => {
          this.$router.push("/");
        })
        .catch((e) => {
          Swal.fire({
            icon: "error",
            title: "Oopss..",
            text: `El usuario y la contraseña no son validas.`,
          });
        });
    },
    async activarNotificaciones() {
      // Comprobamos si el navegador soporta las notificaciones
      if (!("Notification" in window)) {
        alert(
          "No podrás obtener notificaciones del sistema ya que tu navegador no lo soporta."
        );
      }

      // Comprobamos si ya nos habían dado permiso
      else if (Notification.permission !== "granted") {
        await Swal.fire(
          "Notificaciones",
          `<p>Para recibir notifiaciones cuando estén listos tus recibos o se haya subido información de interes, por favor presiona "Permitir" en el cartel que saldrá a continuación.</p>`,
          "info"
        );
        Notification.requestPermission(function (permission) {
          // Si el usuario acepta, lanzamos la notificación
          console.log(permission)
        });
      }
    },
  },
};
</script>
